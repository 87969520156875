<template>
  <div class="submenu" id="destinations" :key="containerKey">
    <ul class=" row align-top align-justify">
      <li class="destinations-col" id="regions-col">
        <div class="submenu__headline first-menu-select" @click="setCurrentSector(d,i)" v-for="(d,i) in destinations" :key="d[0]" :class="{ selected: currentSector[0] == d[0]}">
          <a :class="{ active: currentSector[0] == d[0]}" >{{ d[0] }}</a>
        </div>
      </li>
      <li class="destinations-col" id="countries-col">
        <div class="submenu__headline" id="sectorHeader"> <span>{{currentSector[0]}}</span> </div>
        <ul class="destinations-submenu">
          <li class="submenu__link" v-for="(c,i) in currentSector[1]" :key="c[1]" >
            <a @click="setCurrentCountry(c)">{{ c[0] }}</a>
          </li>
        </ul>
      </li>
      <li class="destinations-col"  id="countryHeader">
        <template v-if="currentCountry.length">
          <div class="submenu__headline"> <span>{{currentCountry[0]}}</span> </div>
          <ul class="destinations-submenu">
            <li class="submenu__link" v-for="r in currentCountry[2]" :key="r">
              <a :href="`/de/destination/${currentCountry[0]}/${r}`">{{r}}</a>
            </li>
            <li  class="submenu__link">
              <a class="cta cta--small cta--inverse" :href="`/de/destination/${currentCountry[1]}`">Alle Angebote in {{currentCountry[0]}} </a>
            </li>
          </ul>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "DestinationNavi",
  data: function () {
    return {
      destinations: [],
      currentSector: [],
      currentSectorIndex: 0,
      currentCountry: [],
      bigNav: false,
      containerKey: 0
    }
  },
  created(){
    axios.get("/destinations.json")
    .then(response => {
      this.destinations = response.data
    })
    this.bigNav = ((window.innerWidth < 990 || window.innerHeight < 844) ? false : true);
  },
  methods: {
    setCurrentSector(d,i){
      this.currentSector = d
      this.currentSectorIndex = i
      this.currentCountry = []

      if (this.bigNav == false){
        $("nav").animate({ scrollTop: $("#sectorHeader").offset().top + 50 });
      }
    },
    setCurrentCountry(c){
      if (c[2].length){
        this.currentCountry = c
        if (this.bigNav == false){
          $("nav").animate({ scrollTop: $("#countryHeader").offset().top + 50  });
        }
      }else{
        window.open(`/de/destination/${c[1]}`)
      }

    }
  }
}
</script>
<style>

#destinations .cta--inverse { color: #888 !important;  }
#destinations .cta--inverse:hover { color: #fff !important; }
#destinations .cta--small { padding: 5px 12px !important ; white-space: break-spaces; }
</style>